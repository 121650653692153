





































import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
    data() {
        return {
            email: '',
            password: '',
        }
    },
    methods: {
        ...mapActions('login', {
            logIn: 'logIn',
        }),
        onSubmit() {
            this.logIn({
                email: this.email,
                password: this.password,
            }).then(() => {
                this.$router.push({ name: 'home' })
            })
        },
    },
})
